:root {
  --primary: #edecd1;
  --secondary: #dde586;
  --background: '#EDECD130';
}

* {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
  padding: '0';
  margin: '0';
}

.MuiDrawer-paper {
  background-color: #edecd1f1 !important;
}
